<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>{{i18nConst.CASINO['CASINO_SLOT'][$store.state.country]}}</sub-title>
                <div class="main_panel">
                    <div class="casino_panel">
                        <div class="casino_re_panel">
                            <div class="p1">
                                {{i18nConst.CASINO['CASINO_CASH_CONVERSION'][$store.state.country]}}
                            </div>
                            <div class="p2">
                                <div class="t1">{{i18nConst.COMMON['AMOUNT'][$store.state.country]}}</div>
                                <div class="t2">
                                    <input type="number" v-model="casinocash" placeholder="금액">
                                </div>
                                <div class="t3">
                                    <button type="button" class="btn-all" @click="allMoney()">{{i18nConst.GAME_TYPE['ALL'][$store.state.country]}}</button>
                                    <button type="button" class="btn-reset" @click="changeCasinoMoney(0)">{{i18nConst.BET['INIT'][$store.state.country]}}</button>
                                </div>
                            </div>
                            <div class="p3">
                                <button class="btn-change"  @click="change2casinocash()">{{i18nConst.CASINO['CASINO_CASH_CONVERSION'][$store.state.country]}}</button>
                            </div>
                        </div>
                        <div class="casino_re_panel">
                            <div class="p1">
                                {{i18nConst.CASINO['SPORTS_CASH_CONVERSION'][$store.state.country]}}
                                <span style="color: #ffa500">{{i18nConst.COIN['COIN_RATE2'][$store.state.country]}} :  {{$store.state.commonConfig.hlv}} KRW</span>
                            </div>
<!--                            <div class="t2">-->
<!--                                <input type="number" v-model="casinocash2" placeholder="금액">-->
<!--                            </div>-->
                            <div class="p2" style="text-align: center">
                                <span style="width: 100%;height: 28px;line-height: 28px">{{i18nConst.CASINO['CASINO_CASH_CONVERSION_MSG_1'][$store.state.country]}}</span>
                            </div>`
                            <div class="p3">
                                <button class="btn-change"  @click="change2sportsmoney()">{{i18nConst.CASINO['SPORTS_CASH_CONVERSION'][$store.state.country]}}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="casino_panel">
                    <div class="casino">
                        <div @click="openCasinoGame(45)">
                            <img src="../../assets/images/casino/mtree/06.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(1)">
                            <img src="../../assets/images/casino/mtree/04.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(5)">
                            <img src="../../assets/images/casino/mtree/03.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(16)">
                            <img src="../../assets/images/casino/mtree/05.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(9)">
                            <img src="../../assets/images/casino/mtree/07.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(28)">
                            <img src="../../assets/images/casino/mtree/08.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(44)">
                            <img src="../../assets/images/casino/mtree/02.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(21)">
                            <img src="../../assets/images/casino/mtree/01.jpg" alt="">
                        </div>
                    </div>

                </div>
                <sub-title>{{i18nConst.CASINO['SLOT_GAMES'][$store.state.country]}}</sub-title>
                <div class="casino_panel">
                    <div class="button_group">
                        <button class="btn-slot-comp bw4" v-for="(item,index) in slotCompanies" @click="getGameListByCode(item.code)">
                            {{item.name}}
                        </button>
                    </div>
                    <div class="slot_game" style="margin-top: 10px">
                        <div v-for="(item,index) in slotGameList" :key="index" @click="openSlotGame(item.id)">
                            <img :src="item.img" alt="">
                            <span class="name">{{item.tKR}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import SubTitle from "../../components/SubTitle";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {
        addMemberCasinoCash,
        getAccountBalance, getCasinoMaintaining,
        getGameList,
        getGameUrl,
        getLobbyUrl, subjectMemberCasinoCash, subjectMemberCasinoCash2
    } from "../../network/casinoRequest";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import i18nConst from "../../common/i18nConst";


    export default {
        name: "CasinoSlotGame",
        mixins:[postionMixin],
        components: {
            RightButtonsComp,
            UserInfoComp,
            SportsBetCartComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp, TopbarComp, SubTitle, ScrollTopComp, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                i18nConst:i18nConst,
                slotCompanies: [
                    {'code': '8', 'name': i18nConst.CASINO['PROGRAMMATIC_SLOT'][this.$store.state.country]},
                    {'code': '19', 'name': i18nConst.CASINO['RTG_SLOT'][this.$store.state.country]},
                    {'code': '11', 'name': i18nConst.CASINO['STOKRAT_SLOT'][this.$store.state.country]},
                    {'code': '23', 'name': i18nConst.CASINO['CQ9_SLOT'][this.$store.state.country]},
                    {'code': '38', 'name': i18nConst.CASINO['BGAMES_SLOT'][this.$store.state.country]},
                    {'code': '44', 'name': i18nConst.CASINO['AG_SLOT'][this.$store.state.country]},
                    {'code': '15', 'name': i18nConst.CASINO['BBIN_SLOT'][this.$store.state.country]},
                    {'code': '40', 'name': i18nConst.CASINO['EVOPLAY_SLOT'][this.$store.state.country]},
                ],
                currentSlotCode: '8',
                slotGameList: [],
                sportsConst,
                casinocash: 0,
                casinocash2: 0,
                position:"Casino",

            }
        },
        methods: {
            change2sportsmoney(){
                // if(this.casinocash2 <= 0){
                //     this.$swal({
                //         title: i18nConst.COMMON['CONVERSION_FAILED2'][this.$store.state.country],
                //         type: 'error',
                //         showCancelButton: false,
                //         showConfirmButton: true
                //     })
                //     return false;
                // }
                this.$swal({
                    title: i18nConst.CASINO['CASINO_CASH_CONVERSION_MSG_2'][this.$store.state.country],
                    type: 'question',
                    confirmButtonText: ' '+i18nConst.COMMON['YES'][this.$store.state.country]+' ',
                    cancelButtonText: i18nConst.COMMON['NO'][this.$store.state.country]
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        subjectMemberCasinoCash(this.casinocash2).then((res)=>{
                            if(res.data.success){
                                this.$swal({
                                    title: i18nConst.COMMON['CONVERSION_SUCCESS'][this.$store.state.country],
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.casinocash = 0;
                                this.$store.dispatch('actionUserInfo')
                                setTimeout(()=>{
                                    this.getCasinoBalance();
                                },1300)
                            }else {
                                this.$swal({
                                    title: i18nConst.COMMON['CONVERSION_FAILED'][this.$store.state.country]+', ' + res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }

                        })
                    }
                })

            },
            getCasinoBalance(){
                getAccountBalance().then(res => {
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            },
            changeCasinoMoney(cash) {
                if (cash === 0) {
                    this.casinocash = 0;
                } else {
                    this.casinocash += parseInt(cash);
                }
            },
            change2casinocash() {
                if (this.casinocash > this.$store.state.userInfo.cash) {
                    this.$swal({
                        title: '보유금액 ' + this.$store.state.userInfo.cash + '$를 초과하였습니다',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                addMemberCasinoCash(this.casinocash).then(res => {
                    if (res.data.success) {
                        this.$swal({
                            title: i18nConst.COMMON['CONVERSION_SUCCESS'][this.$store.state.country],
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.casinocash = 0;
                        this.$store.dispatch('actionUserInfo')
                        setTimeout(()=>{
                            this.getCasinoBalance();
                        },1300)

                    } else {
                        this.$swal({
                            title: i18nConst.COMMON['CONVERSION_FAILED'][this.$store.state.country]+', ' + res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            openCasinoGame(thirdPartyCode) {
                getLobbyUrl(thirdPartyCode).then((res) => {
                    if (res.data.success) {
                        let url = res.data.data;
                        window.open(url, '_blank')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            openSlotGame(gameId){
                getGameUrl(gameId).then(res=>{
                    if (res.data.success) {
                        let url = res.data.data;
                        window.open(url, '_blank')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            getGameListByCode(thirdPartyCode) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getGameList(thirdPartyCode).then(res => {
                    if (res.data.success) {
                        this.slotGameList = JSON.parse(res.data.data).games;
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                });
            },
            allMoney(){
                this.casinocash = this.$store.state.userInfo.cash

            }
        },
        created() {
            if(this.$store.state.isLogin){
                getCasinoMaintaining().then(res=>{
                    if(res.data.success){
                        if(!res.data.data){
                            this.$swal({
                                //카지노점검중입니다
                                title: i18nConst.CASINO["1"][this.$store.state.country],
                                type: 'error',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        }else {
                            this.getGameListByCode(this.currentSlotCode);
                            if(this.$store.state.isLogin){
                                this.getCasinoBalance()
                            }
                        }
                    }else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }

                })
            }
        }
    }
</script>

<style scoped>

    .casino_panel{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 16px;
    }
    .casino_panel .casino_re_panel{
        width: 49.6%;
        height: 190px;
        background-color: #0e3b88;
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 3px;
    }
    .casino_panel .casino_re_panel .p1{
        width: 100%;
        text-align: center;
        padding: 10px 0;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
    }
    .casino_panel .casino_re_panel .p2{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        text-align: center;
        padding: 10px 0;
        color: #fff9ff;
        font-size: 14px;
        font-weight: 600;
    }
    .casino_panel .casino_re_panel .p2 .t1{
        text-align: center;
        width: 30%;
    }
    .casino_panel .casino_re_panel .p2 .t2{
        text-align: center;
        width: 40%;
    }
    .t2 input{
        width: 100%;
        text-align: center;
        background-color: #ffffff;
        height: 28px;
        color: #0b1423;
    }
    .casino_panel .casino_re_panel .p2 .t3{
        text-align: center;
        width: 30%;
    }
    .casino_panel .casino_re_panel .p2 .t3 button{
        height: 28px;
        padding: 0 3px;
        border-radius: 2px;
    }
    .casino_panel .casino_re_panel .p2 .btn-all{
        background-color: #1f6cfe;
    }
    .casino_panel .casino_re_panel .p2 .btn-reset{
        background-color: #db800d;
        margin-left: 5px;
    }

    .casino_panel .casino_re_panel .p3{
        text-align: center;
    }
    .casino_panel .casino_re_panel .btn-change{
        height: 36px;
        padding: 0 16px;
        background-color: #ff8b03;
        border-radius: 2px;
        color: #ffffff;
        font-weight: 600;
    }
    .casino_panel .button_group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .casino_panel .btn-slot-comp{
        background-color: #db800d;
        margin-bottom: 2px;
        border-radius: 3px;
    }


    @media screen and (max-width: 1024px) {
        .casino_panel .casino_re_panel{
            width: 100%;
            margin-bottom: 16px;
        }
    }

    .button_group a{
        margin-top: 3px;
    }

    .board_list .reex_casino{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .reex_casino .board_box{
        width: 49.3%;
    }

    .casino_panel .casino {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .casino_panel .casino div {
        width: 24.5%;
        box-sizing: border-box;
        padding: 10px 10px;
        text-align: center;
    }

    .casino_panel .casino {
        max-width: 100%;
        cursor: pointer;
    }
    .casino img{
        width: 100%;
        border: 1px solid #008400;
        border-radius: 2px;
    }

    .casino_panel .slot_company{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        text-align: center;
        margin: 10px auto;
    }


    .casino_panel .slot_game {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        text-align: center;
    }
    .casino_panel .slot_game .name {
        display:block;
        line-height: 50px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .casino_panel .slot_game div {
        width: 16%;
        border-bottom: 1px solid #4a4a4a;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .casino_panel .slot_game img{
        width: 100%;
        border: 1px solid #f4c600;
        border-radius: 5px;
    }

    @media screen and (max-width: 800px) {
        .casino div {
            width: 100%!important;
            box-sizing: border-box;
            padding: 10px 10px;
        }
        .casino_panel .slot_game div {
            width: 45.5%;
        }
        .casino_panel .slot_company a{
            width: 48%!important;
        }
        .casino_panel .board_box{
            width: 100%!important;
        }

    }
</style>